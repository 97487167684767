var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"complaint-detail"},[_c('section-title',{attrs:{"has-line":"","title":"Detalle de la Querella"}}),_c('div',{staticClass:"input-container row mx-0"},[_c('div',{staticClass:"col-12 mb-3 mb-md-4"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-drop-down',{attrs:{"clearable":false,"custom-error-msg":"Favor seleccione mecanismo para dilucidar.","error":errors[0],"fieldkey":"id","fieldname":"name","label":"Mecanismo para Dilucidar","options":_vm.mechinismOptions,"placeholder":"Seleccione"},model:{value:(_vm.model.elucidateType),callback:function ($$v) {_vm.$set(_vm.model, "elucidateType", $$v)},expression:"model.elucidateType"}})]}}])})],1),_c('div',{staticClass:"col-12 mb-3 mb-md-4"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-text-area',{attrs:{"id":"conditions","count-classes":['d-block', 'text-right'],"custom-error-msg":"Favor escriba las condiciones específicas de la querella.","error":errors[0],"label":"Condiciones Específicas","maxlength":500,"name":"conditions","show-char-count":""},model:{value:(_vm.model.condition),callback:function ($$v) {_vm.$set(_vm.model, "condition", $$v)},expression:"model.condition"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6 mb-3 mb-md-4"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-text-area',{attrs:{"id":"complaintDetail","count-classes":['d-block', 'text-right'],"custom-error-msg":"Favor escriba detalladamente los hechos que le motivan a radicar esta querella.","error":errors[0],"label":"Explique detalladamente los hechos que le motivan a radicar esta querella.","maxlength":500,"name":"complaintDetail","show-char-count":""},model:{value:(_vm.model.reason),callback:function ($$v) {_vm.$set(_vm.model, "reason", $$v)},expression:"model.reason"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6 mb-3 mb-md-4"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-text-area',{attrs:{"id":"complaintRemedy","count-classes":['d-block', 'text-right'],"custom-error-msg":"Favor escriba el remedio que propone para resolver esta controversia.","error":errors[0],"label":"¿Qué remedio propone para resolver esta controversia?","maxlength":500,"name":"complaintRemedy","show-char-count":""},model:{value:(_vm.model.allegation),callback:function ($$v) {_vm.$set(_vm.model, "allegation", $$v)},expression:"model.allegation"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6 mb-3 mb-md-4"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-file-input',{ref:"fileUpload",staticClass:"custom-document-input",attrs:{"accept":".pdf","custom-error-msg":"Favor subir un documento explicando detalladamente los hechos que le motivan a radicar esta querella.","display-style":"normal","error":errors[0],"label":"Explique detalladamente los hechos que le motivan a radicar esta querella y remedio que propone para resolver la controversia.","label-position":"left","on-download":function () {},"required":""},model:{value:(_vm.model.file),callback:function ($$v) {_vm.$set(_vm.model, "file", $$v)},expression:"model.file"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6 mb-3 mb-md-4"},[_c('div',[_vm._v(" Favor descargar la copia del documento de derechos de los padres ")]),(_vm.parentRightsDocument.filename)?_c('validation-provider',{attrs:{"rules":{ required: _vm.model.deliveredDocument == null }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center mt-3"},[_c('span',{staticClass:"mr-3"},[_vm._v("Derecho de Padres (Copia)")]),_c('button',{staticClass:"btn btn-outline-success mr-2 ml-2 download-btn-size",attrs:{"type":"button"},on:{"click":_vm.downloadFile}},[_c('i',{staticClass:"fas fa-file-download"})])]),(errors[0])?_c('error',{attrs:{"message":"Para poder radicar la querella, deberá descargar el documento de derecho de los padres.","variant":"no-border"}}):_vm._e()]}}],null,false,2794516476)}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }