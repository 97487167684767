<template>
  <div class="complaint-affairs">
    <section-title has-line title="Asuntos (Controversias)" />
    <div class="input-container">
      <validation-observer ref="affairForm" disabled>
        <div class="row mx-0">
          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <validation-provider v-slot="{ errors }" rules="required">
              <custom-drop-down
                v-model="currentSelection"
                :clearable="false"
                custom-error-msg="Favor seleccione el asunto (controversia)."
                :error="errors[0]"
                fieldkey="id"
                fieldname="name"
                label="Seleccione el asunto:"
                :options="availableAffairs"
                placeholder="Seleccione"
              />
            </validation-provider>
          </div>
          <div class="col-12 col-md-6 pt-md-2">
            <action-button
              i="fas fa-file-plus"
              text="Añadir Asunto"
              variant="success btn-block-xs-only mt-md-4"
              @click="addAffair"
            />
          </div>
        </div>
      </validation-observer>
      <div class="px-3">
        <validation-provider
          v-slot="{ errors }"
          :rules="{ required: selectedAffairs.length == 0 }"
        >
          <custom-chips-group
            class="custom-chips"
            no-background
            no-wrap
            object-text="name"
            :on-item-deleted="onDeleteAffairHandler"
            :selected-items="selectedAffairs"
            title="Asunto(s) seleccionado(s):"
          />
          <custom-error
            v-if="errors[0]"
            class="mt-2"
            message="Favor seleccionar al menos un asunto."
            variant="no-border"
          />
        </validation-provider>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from 'SectionTitle';
import CustomDropDown from 'custom-drop-down';
import ActionButton from 'ActionButton';
import CustomChipsGroup from 'CustomChipsGroup';
import CustomError from 'custom-error';

export default {
  name: 'ComplaintAffairs',
  components: {
    SectionTitle,
    CustomDropDown,
    ActionButton,
    CustomChipsGroup,
    CustomError,
  },
  props: {
    affairs: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    currentSelection: null,
    selectedAffairs: [],
  }),
  computed: {
    availableAffairs() {
      return this.affairs.filter((d) =>
        this.selectedAffairs.every((item) => item.id != d.id)
      );
    },
  },
  methods: {
    async addAffair() {
      const valid = await this.$refs.affairForm.validate();
      if (!valid) return;
      this.selectedAffairs.push(this.currentSelection);
      this.currentSelection = null;
      await this.resetForm();
    },
    onDeleteAffairHandler(affair) {
      this.selectedAffairs.splice(this.selectedAffairs.indexOf(affair), 1);
    },
    getModel() {
      return { affairs: this.selectedAffairs.map((a) => a.id) };
    },
    async resetForm() {
      await this.$refs.affairForm.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/colors.scss';

.complaint-affairs {
  .custom-chips::v-deep {
    .chips-container {
      border-top: 1px solid map-get($colors, 'gray-2');
      p.bold {
        color: map-get($colors, 'black');
        font-weight: normal !important;
      }
    }
  }
}
</style>
