<template>
  <div class="complaint-detail">
    <section-title has-line title="Detalle de la Querella" />
    <div class="input-container row mx-0">
      <div class="col-12 mb-3 mb-md-4">
        <validation-provider v-slot="{ errors }" rules="required">
          <custom-drop-down
            v-model="model.elucidateType"
            :clearable="false"
            custom-error-msg="Favor seleccione mecanismo para dilucidar."
            :error="errors[0]"
            fieldkey="id"
            fieldname="name"
            label="Mecanismo para Dilucidar"
            :options="mechinismOptions"
            placeholder="Seleccione"
          />
        </validation-provider>
      </div>
      <div class="col-12 mb-3 mb-md-4">
        <validation-provider v-slot="{ errors }" rules="required">
          <custom-text-area
            id="conditions"
            v-model="model.condition"
            :count-classes="['d-block', 'text-right']"
            custom-error-msg="Favor escriba las condiciones específicas de la querella."
            :error="errors[0]"
            label="Condiciones Específicas"
            :maxlength="500"
            name="conditions"
            show-char-count
          />
        </validation-provider>
      </div>
      <div class="col-12 col-md-6 mb-3 mb-md-4">
        <validation-provider v-slot="{ errors }" rules="required">
          <custom-text-area
            id="complaintDetail"
            v-model="model.reason"
            :count-classes="['d-block', 'text-right']"
            custom-error-msg="Favor escriba detalladamente los hechos que le motivan a radicar esta querella."
            :error="errors[0]"
            label="Explique detalladamente los hechos que le motivan a radicar esta querella."
            :maxlength="500"
            name="complaintDetail"
            show-char-count
          />
        </validation-provider>
      </div>
      <div class="col-12 col-md-6 mb-3 mb-md-4">
        <validation-provider v-slot="{ errors }" rules="required">
          <custom-text-area
            id="complaintRemedy"
            v-model="model.allegation"
            :count-classes="['d-block', 'text-right']"
            custom-error-msg="Favor escriba el remedio que propone para resolver esta controversia."
            :error="errors[0]"
            label="¿Qué remedio propone para resolver esta controversia?"
            :maxlength="500"
            name="complaintRemedy"
            show-char-count
          />
        </validation-provider>
      </div>
      <div class="col-12 col-md-6 mb-3 mb-md-4">
        <validation-provider v-slot="{ errors }" rules="required">
          <custom-file-input
            ref="fileUpload"
            v-model="model.file"
            accept=".pdf"
            class="custom-document-input"
            custom-error-msg="Favor subir un documento explicando detalladamente los hechos que le motivan a radicar esta querella."
            display-style="normal"
            :error="errors[0]"
            label="Explique detalladamente los hechos que le motivan a radicar esta querella y remedio que propone para resolver la controversia."
            label-position="left"
            :on-download="() => {}"
            required
          />
        </validation-provider>
      </div>
      <div class="col-12 col-md-6 mb-3 mb-md-4">
        <div>
          Favor descargar la copia del documento de derechos de los padres
        </div>
        <validation-provider
          v-if="parentRightsDocument.filename"
          v-slot="{ errors }"
          :rules="{ required: model.deliveredDocument == null }"
        >
          <div class="d-flex align-items-center mt-3">
            <span class="mr-3">Derecho de Padres (Copia)</span>
            <button
              class="btn btn-outline-success mr-2 ml-2 download-btn-size"
              type="button"
              @click="downloadFile"
            >
              <i class="fas fa-file-download" />
            </button>
          </div>
          <error
            v-if="errors[0]"
            message="Para poder radicar la querella, deberá descargar el documento de derecho de los padres."
            variant="no-border"
          />
        </validation-provider>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from 'SectionTitle';
import CustomTextArea from 'CustomTextArea';
import CustomFileInput from 'CustomFileInput';
import CustomDropDown from 'custom-drop-down';
import Error from 'custom-error';
import yesNoOptions from '@/utils/constants/hrrequest/yesNoOptions';
import mechinismOptions from '@/utils/constants/complaint/mechinism';
import { downloadBlobToFile } from '@/utils/blob';

export default {
  name: 'ComplaintDetail',
  components: {
    SectionTitle,
    CustomTextArea,
    CustomFileInput,
    CustomDropDown,
    Error,
  },
  props: {
    parentRightsDocument: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    model: {
      condition: null,
      reason: null,
      allegation: null,
      deliveredDocument: null,
      file: null,
      elucidateType: null,
    },
  }),
  computed: {
    yesNoOptions() {
      return yesNoOptions;
    },
    mechinismOptions() {
      return mechinismOptions;
    },
  },
  methods: {
    getModel() {
      return this.model;
    },
    downloadFile() {
      const document = this.parentRightsDocument;
      if (document?.content) {
        this.model.deliveredDocument = true;
        downloadBlobToFile(document);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.complaint-detail::v-deep {
  .custom-document-input {
    .input-label {
      font-weight: normal;
      margin-bottom: 0.7rem;
      display: inline-block;
    }
  }
}
.download-btn-size {
  height: 33px;
  width: 33px;
  padding: 0;
}
</style>
