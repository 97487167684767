var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"student-info"},[_c('section-title',{attrs:{"has-line":"","title":"Información del Estudiante"}}),_c('div',{staticClass:"input-container"},[_c('detail-generator',{attrs:{"col-gutter":"mb-2 mb-md-3","details":_vm.studentInfo}}),_c('div',{staticClass:"rounded bg-white px-3 pt-3 pb-2"},[_c('span',{staticClass:"color-dark-gray font-weight-bold"},[_vm._v(" Lugar donde el estudiante recibe servicios educativos ")]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 col-md-4 mb-3"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-drop-down',{attrs:{"clearable":false,"custom-error-msg":"El lugar es requerido.","error":errors[0],"fieldkey":"id","fieldname":"name","label":"Lugar:","options":_vm.options.places,"placeholder":"Seleccione"},on:{"input":function($event){return _vm.getDistricts()}},model:{value:(_vm.model.place),callback:function ($$v) {_vm.$set(_vm.model, "place", $$v)},expression:"model.place"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 mb-3"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-drop-down',{attrs:{"clearable":false,"custom-error-msg":"La región es requerida.","disabled":_vm.model.place == null,"error":errors[0],"fieldkey":"id","fieldname":"name","label":"Región:","options":_vm.options.regions,"placeholder":"Seleccione"},on:{"input":function($event){return _vm.getDistricts()}},model:{value:(_vm.model.region),callback:function ($$v) {_vm.$set(_vm.model, "region", $$v)},expression:"model.region"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 mb-3"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-drop-down',{attrs:{"clearable":false,"custom-error-msg":"El distrito es requerido.","disabled":!Boolean(_vm.options.districts.length),"error":errors[0],"fieldkey":"id","fieldname":"name","label":"Distrito:","options":_vm.options.districts,"placeholder":"Seleccione"},on:{"input":function($event){return _vm.getMunicipalities()}},model:{value:(_vm.model.district),callback:function ($$v) {_vm.$set(_vm.model, "district", $$v)},expression:"model.district"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 mb-3"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-drop-down',{attrs:{"clearable":false,"custom-error-msg":"El municipio es requerido.","disabled":!Boolean(_vm.options.municipalities.length),"error":errors[0],"fieldkey":"id","fieldname":"name","label":"Municipio:","options":_vm.options.municipalities,"placeholder":"Seleccione"},on:{"input":function($event){return _vm.getSchools()}},model:{value:(_vm.model.municipality),callback:function ($$v) {_vm.$set(_vm.model, "municipality", $$v)},expression:"model.municipality"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-8"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-drop-down',{attrs:{"clearable":false,"custom-error-msg":"La escuela es requerida.","disabled":!Boolean(_vm.options.schools.length),"error":errors[0],"fieldkey":"id","fieldname":"name","label":"Escuela:","options":_vm.options.schools,"placeholder":"Seleccione"},model:{value:(_vm.model.school),callback:function ($$v) {_vm.$set(_vm.model, "school", $$v)},expression:"model.school"}})]}}])})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }