const mechinismOptions = [
  {
    id: 1,
    name: 'Conciliador',
  },
  {
    id: 2,
    name: 'Mediador',
  },
];

export default mechinismOptions;
