<template>
  <div class="student-info">
    <section-title has-line title="Información del Estudiante" />
    <div class="input-container">
      <detail-generator col-gutter="mb-2 mb-md-3" :details="studentInfo" />
      <div class="rounded bg-white px-3 pt-3 pb-2">
        <span class="color-dark-gray font-weight-bold">
          Lugar donde el estudiante recibe servicios educativos
        </span>
        <div class="row mt-3">
          <div class="col-12 col-md-4 mb-3">
            <validation-provider v-slot="{ errors }" rules="required">
              <custom-drop-down
                v-model="model.place"
                :clearable="false"
                custom-error-msg="El lugar es requerido."
                :error="errors[0]"
                fieldkey="id"
                fieldname="name"
                label="Lugar:"
                :options="options.places"
                placeholder="Seleccione"
                @input="getDistricts()"
              />
            </validation-provider>
          </div>
          <div class="col-12 col-md-4 mb-3">
            <validation-provider v-slot="{ errors }" rules="required">
              <custom-drop-down
                v-model="model.region"
                :clearable="false"
                custom-error-msg="La región es requerida."
                :disabled="model.place == null"
                :error="errors[0]"
                fieldkey="id"
                fieldname="name"
                label="Región:"
                :options="options.regions"
                placeholder="Seleccione"
                @input="getDistricts()"
              />
            </validation-provider>
          </div>
          <div class="col-12 col-md-4 mb-3">
            <validation-provider v-slot="{ errors }" rules="required">
              <custom-drop-down
                v-model="model.district"
                :clearable="false"
                custom-error-msg="El distrito es requerido."
                :disabled="!Boolean(options.districts.length)"
                :error="errors[0]"
                fieldkey="id"
                fieldname="name"
                label="Distrito:"
                :options="options.districts"
                placeholder="Seleccione"
                @input="getMunicipalities()"
              />
            </validation-provider>
          </div>
          <div class="col-12 col-md-4 mb-3">
            <validation-provider v-slot="{ errors }" rules="required">
              <custom-drop-down
                v-model="model.municipality"
                :clearable="false"
                custom-error-msg="El municipio es requerido."
                :disabled="!Boolean(options.municipalities.length)"
                :error="errors[0]"
                fieldkey="id"
                fieldname="name"
                label="Municipio:"
                :options="options.municipalities"
                placeholder="Seleccione"
                @input="getSchools()"
              />
            </validation-provider>
          </div>
          <div class="col-12 col-md-8">
            <validation-provider v-slot="{ errors }" rules="required">
              <custom-drop-down
                v-model="model.school"
                :clearable="false"
                custom-error-msg="La escuela es requerida."
                :disabled="!Boolean(options.schools.length)"
                :error="errors[0]"
                fieldkey="id"
                fieldname="name"
                label="Escuela:"
                :options="options.schools"
                placeholder="Seleccione"
              />
            </validation-provider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from 'SectionTitle';
import CustomDropDown from 'custom-drop-down';
import DetailGenerator from '@/components/common/DetailGenerator.vue';
import {
  getDistricts,
  getMunicipalities,
  getMunicipalitySchools,
} from '@/services/location/locationApi';

export default {
  name: 'StudentInfo',
  components: { SectionTitle, CustomDropDown, DetailGenerator },
  props: {
    academicYearId: {
      type: Number,
      default: () => 0,
    },
    info: {
      type: Object,
      default: () => {},
    },
    studentLocation: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {
        return {
          places: [],
          regions: [],
          districts: [],
          municipalities: [],
          schools: [],
        };
      },
    },
  },
  data: () => ({
    model: {
      place: null,
      region: null,
      district: null,
      municipality: null,
      school: null,
    },
  }),
  computed: {
    studentInfo() {
      return [
        {
          info: [
            {
              label: 'Nombre del Estudiante:',
              data: this.info.name,
              class: 'col-12 col-md-3',
            },
            {
              label: 'Número de SIE:',
              data: this.info.studentSieId,
              class: 'col-12 col-md-3',
            },
            {
              label: 'Número de Registro:',
              data: this.info.regStudentId,
              class: 'col-12 col-md-3',
            },
            {
              label: 'Grado:',
              data: this.info.grade,
              class: 'col-12 col-md-3',
            },
            {
              label: 'Determinación de Elegibilidad:',
              data: this.info.impediment?.name,
              class: 'col-12 col-md-3',
            },
            {
              label: 'Alternativa de Ubicación:',
              data: this.info.alternatePlacementDescription,
              class: 'col-12 col-md-3',
            },
          ],
        },
      ];
    },
  },
  watch: {
    studentLocation: {
      immediate: true,
      async handler(newVal) {
        if (newVal && Object.keys(newVal).length) {
          this.model = {
            place: newVal.locationType,
            district: newVal.district,
            municipality: newVal.municipality,
            school: newVal.school,
            region: newVal.region,
          };
          await Promise.all([
            this.getDistricts(true),
            this.getMunicipalities(true),
            this.getSchools(true),
          ]);
        }
      },
    },
  },
  methods: {
    async getDistricts(notReset) {
      const region = this.model.region;
      const schoolType = this.model.place;

      if (region && schoolType) {
        const { data } = await getDistricts({
          schoolTypes: schoolType.id,
          regionId: region.id,
          academicYearId: this.academicYearId,
        });
        this.options.districts = data;

        if (notReset) return;

        this.model = {
          ...this.model,
          district: null,
          school: null,
          municipality: null,
        };
        this.options = {
          ...this.options,
          schools: [],
          municipalities: [],
        };
        this.reset();
      }
    },
    async getMunicipalities(notReset) {
      if (this.model.district && this.model.region) {
        const params = {
          academicYearId: this.academicYearId,
          regionId: this.model.region.id,
          schoolTypes: this.model.place.id,
          districtId: this.model.district.id,
        };
        const { data } = await getMunicipalities(params);
        this.options.municipalities = data;

        if (notReset) return;

        this.model = {
          ...this.model,
          school: null,
          municipality: null,
        };

        this.options = {
          ...this.options,
          schools: [],
        };
        this.reset();
      }
    },
    async getSchools(notReset) {
      const model = { ...this.model };
      const validModel = () =>
        model.place && model.region && model.district && model.municipality;

      if (validModel()) {
        const params = {
          academicYearId: this.academicYearId,
          schoolTypes: model.place.id,
          districtId: model.district.id,
          municipalityId: model.municipality.id,
          regionId: model.region.id,
        };
        const { data } = await getMunicipalitySchools(params);
        this.options.schools = data;

        if (notReset) return;
        this.model.school = null;
        this.reset();
      }
    },
    getModel() {
      return this.model;
    },
    reset() {
      this.$emit('reset-form');
    },
  },
};
</script>
