<template>
  <div class="complaint-filing">
    <page-header
      :actions="headerActions"
      :page="{
        icon: 'fas fa-user-circle',
        title: 'Radicación de Querella',
        routes: ['Inicio', 'Radicación de Querella'],
      }"
    />
    <validation-observer ref="complaintForm">
      <section-title has-line title="Información de la Querella" />
      <div class="input-container">
        <validation-provider v-slot="{ errors }" rules="required">
          <custom-date-picker
            v-model="model.complaintDate"
            class="mb-2 mt-2 mt-md-0"
            custom-error-msg="Favor seleccione la fecha."
            disabled
            :error="errors[0]"
            label="Fecha:"
            placeholder="DD/MM/YYYY"
            :use-utc="false"
          />
        </validation-provider>
      </div>
      <student-info
        :ref="componentRef.studentInfo"
        :academic-year-id="options.academicYear.id"
        :info="currentStudent"
        :options="options"
        :student-location="options.infoStudentLocation"
        @reset-form="resetFormHandler"
      />
      <complaint-detail
        :ref="componentRef.complaintDetail"
        :parent-rights-document="options.parentRightsDocument"
      />
      <complaint-affairs
        :ref="componentRef.complaintAffairs"
        :affairs="options.affairs"
        @reset-form="resetFormHandler"
      />
      <legal-representation
        :ref="componentRef.legalRepresentation"
        :lawyers="options.lawyers"
        @on-refresh-lawyers="onRefreshLawyersHandler"
      />
    </validation-observer>
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import SectionTitle from 'SectionTitle';
import CustomDatePicker from 'custom-date-picker';
import { serialize } from 'object-to-formdata';
import alert from 'utils/mixins/alert.mixin';
import StudentInfo from '@/views/students/complaints-history/components/StudentInfo.vue';
import ComplaintDetail from '@/views/students/complaints-history/components/ComplaintDetail.vue';
import ComplaintAffairs from '@/views/students/complaints-history/components/ComplaintAffairs.vue';
import LegalRepresentation from '@/views/students/complaints-history/components/LegalRepresentation.vue';
import {
  getSettlementInitFilters,
  createComplaint,
  getLawyers as _getLawyers,
} from '@/services/api/complaints.api';
import { parentModule } from '@/store/modules';

const COMPONENT_REF = {
  studentInfo: 'StudentInfo',
  complaintDetail: 'ComplaintDetail',
  complaintAffairs: 'ComplaintAffairs',
  legalRepresentation: 'LegalRepresentation',
};
import { getLocationTypes, getRegions } from '@/services/location/locationApi';

export default {
  name: 'ComplaintFiling',
  components: {
    PageHeader,
    SectionTitle,
    CustomDatePicker,
    StudentInfo,
    ComplaintDetail,
    ComplaintAffairs,
    LegalRepresentation,
  },
  mixins: [alert],
  props: { studentSieId: { type: [String, Number], default: null } },
  data: () => ({
    model: {
      complaintDate: new Date(),
    },
    componentRef: COMPONENT_REF,
    options: {
      academicYear: {},
      lawyers: [],
      affairs: [],
      infoStudentLocation: {},
      places: [],
      regions: [],
      districts: [],
      municipalities: [],
      schools: [],
    },
  }),
  computed: {
    ...parentModule.mapGetters(['getStudentById']),
    currentStudent() {
      return this.getStudentById(this.studentSieId) || {};
    },
    headerActions() {
      return [
        {
          text: 'Regresar',
          icon: 'fas fa-arrow-circle-left',
          variant: 'outline-success',
          click: () => this.$router.go(-1),
        },
        {
          text: 'Someter',
          icon: 'fas fa-flag-alt',
          variant: 'success',
          click: () => this.submitComplaint(),
        },
      ];
    },
  },
  async mounted() {
    await this.initFilters();
  },
  methods: {
    async initFilters() {
      await getSettlementInitFilters(this.studentSieId).then(
        ({ data }) => (this.options = { ...this.options, ...data })
      );
      await getRegions().then(
        ({ data: regions }) => (this.options = { ...this.options, regions })
      );
      await getLocationTypes().then(
        ({ data: places }) => (this.options = { ...this.options, places })
      );
    },
    getComponentsModel(refs) {
      for (const ref in refs) {
        const component = this.$refs[refs[ref]];
        this.model = { ...this.model, ...component.getModel() };
      }
    },
    async submitComplaint() {
      const isValid = await this.$refs.complaintForm.validate();
      if (!isValid) {
        this.ShowToast(
          'Información Incompleta',
          'Favor completar la información solicitada para poder continuar.',
          'error'
        );
        return;
      }

      const answer = await this.ShowCancelModal(
        'Atención',
        '¿Está seguro que desea someter la querella?',
        'Si',
        'No'
      );
      if (!answer) return;

      this.getComponentsModel(this.componentRef);
      const model = { ...this.model };
      const params = serialize(
        {
          studentSieId: this.studentSieId,
          complaintDate: model.complaintDate,
          // student info
          municipalityId: model.municipality.id,
          regionId: model.region.id,
          districtId: model.district.id,
          locationSchoolId: model.school.id,
          locationSchoolName: model.school.name,
          // complaint detail
          specificCondition: model.condition,
          complaintReason: model.reason,
          complaintAllegation: model.allegation,
          parentRightDocumentSent: model.deliveredDocument,
          elucidateTypeId: model.elucidateType.id,
          elucidateTypeName: model.elucidateType.name,
          document: model.file,
          // affair
          affairs: model.affairs,
          //lawyer
          hasRepresentativeLawyer: model.hasLawyer,
          lawyers: model.lawyers,
        },
        { indices: true, nullsAsUndefineds: true }
      );

      const { data: res } = await createComplaint(params);

      if (res.errors?.length) {
        res.errors.forEach((error) => {
          this.ShowToast('Alerta', error, 'error');
        });
        return;
      }

      if (res.message && res.data == null) {
        this.ShowToast('Alerta', res.message, 'error');
        return;
      }

      this.$router.push({
        name: 'student-complaints_followUp',
        params: { complaintId: res.data.complaintId },
      });
      this.ShowToast(
        'Proceso Exitoso',
        'La querella ha sido radicada exitosamente.',
        'success'
      );
    },
    async resetFormHandler() {
      await this.$refs.complaintForm.reset();
    },
    async onRefreshLawyersHandler() {
      this.options.lawyers = (await _getLawyers()).data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/colors.scss';

::v-deep.complaint-filing {
  .input-container {
    background: map-get($colors, 'gray-3');
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    margin-bottom: 1.5rem;
  }
}
</style>
