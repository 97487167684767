const yesNoOptions = [
  {
    id: true,
    label: 'SI',
  },
  {
    id: false,
    label: 'NO',
  },
];

export default yesNoOptions;
