<template>
  <div class="legal-representation">
    <section-title has-line title="Representación Legal" />
    <div class="input-container">
      <div class="row mx-0 align-items-end">
        <div class="col-md-5 mb-3 mb-md-0">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-drop-down
              v-model="hasLawyer"
              :clearable="false"
              custom-error-msg="Favor seleccione si o no."
              :error="errors[0]"
              fieldkey="id"
              fieldname="label"
              label="¿Estará asistido por un Abogado(a) en el manejo de la Querella?"
              :options="yesNoOptions"
              placeholder="Seleccione"
              @input="hasLawyerSelectionHandler"
            />
          </validation-provider>
        </div>
        <template v-if="displayLawyerFields">
          <div class="col-md-4 col-sm-12">
            <custom-drop-down
              v-model="model.lawyer"
              class="flex-grow-1"
              :clearable="false"
              fieldkey="id"
              fieldname="custom"
              label="Nombre de Abogado:"
              :options="availableLawyers"
              placeholder="Seleccione"
              @input="onChangeLawyerHandler"
            />
          </div>
          <div class="col-md-3 col-sm-12">
            <div class="ml-3 pt-2">
              <action-button
                i="fas fa-user-plus"
                text="Nuevo Abogado"
                variant="success mt-4 pl-3 pr-3 btn-block-xs-only py-1"
                @click="onNewHandler"
              />
            </div>
          </div>
        </template>
      </div>
      <div v-if="displayLawyerFields" class="ml-3">
        <p class="mt-3 mt-md-0">
          De no tener el número de RUA, favor seleccionar NO,
          <br class="d-md-block d-sm-none" />
          y notificar que su abogado asuma representación legal.
        </p>
      </div>
      <div v-if="addedLawyers.length" class="ml-3 bg-white rounded p-2">
        <div
          v-for="(item, index) in addedLawyers"
          :key="`lawyer-${index}`"
          class="row mb-5 mt-2"
        >
          <div class="col-10">
            <div class="row">
              <div class="col-md-3 col-sm col-12 mb-2">
                <div class="color-gray font-14">Nombre de Abogado:</div>
                <div class="color-black font-16">
                  {{ item.name }} {{ item.paternalLastName }}
                  {{ item.maternalLastName }}
                </div>
              </div>
              <div class="col-md-2 col-sm col-12 mt-3 mt-md-0 mt-sm-3">
                <div class="color-gray font-14">RUA:</div>
                <div class="color-black font-16">{{ item.rua }}</div>
              </div>
              <div class="col-md col-sm col-12 mt-3 mt-md-0 mt-sm-3">
                <div class="color-gray font-14">Correo Electrónico:</div>
                <div class="color-black font-16">{{ item.email }}</div>
              </div>
              <div class="col-md-3 col-sm col-12 mt-3 mt-md-0 mt-sm-3">
                <div class="color-gray font-14">Teléfono Celular:</div>
                <div class="color-black font-16">
                  {{ item.cellular | phone }}
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-3 col-12 mt-3 mt-md-0 mt-sm-3">
                <div class="color-gray font-14">Teléfono Oficina:</div>
                <div class="color-black font-16">
                  {{ item.jobPhone | phone }}
                </div>
              </div>
              <div class="col-md-2 col-12 mt-3 mt-md-0 mt-sm-3">
                <div class="color-gray font-14">Extensión:</div>
                <div class="color-black font-16">
                  {{ item.phoneExtension | empty }}
                </div>
              </div>
              <div class="col-md-3 col-sm col-12 mt-3 mt-md-0 mt-sm-3">
                <div class="color-gray font-14">Teléfono de Fax:</div>
                <div class="color-black font-16">{{ item.fax | phone }}</div>
              </div>
            </div>
          </div>
          <div
            class="
              col-2 col-sm
              col-12
              mt-3 mt-md-0 mt-sm-3
              d-flex
              align-items-center
              justify-content-end
              pr-4
            "
          >
            <action-button
              i="fas fa-times-circle"
              variant="outline-danger pl-2 pr-2 btn-block-xs-only mr-3"
              @click="onDeleteHandler(index)"
            />
            <action-button
              i="far fa-file-edit"
              variant="outline-success pl-2 pr-2 btn-block-xs-only"
              @click="onEditHandler(item)"
            />
          </div>
        </div>
      </div>
      <validation-provider
        v-if="displayLawyerFields"
        v-slot="{ errors }"
        :rules="{ required: addedLawyers.length === 0 }"
      >
        <error
          v-if="errors[0]"
          message="Favor seleccionar al menos un abogado."
          variant="no-border ml-3"
        />
      </validation-provider>
      <div class="border__bottom--secondary-12 my-2" />
      <validation-observer ref="legalRepresentationForm">
        <div
          v-if="displayLawyerFields && isAddLawyerMode"
          class="lawyer-form mt-4 ml-3"
        >
          <div class="row">
            <div class="col-4 col-sm col-12 mb-2 mb-md-0">
              <validation-provider :rules="{ required: true, min: 4, max: 5 }">
                <div slot-scope="{ errors, failedRules }">
                  <custom-input
                    v-model="model.rua"
                    class="ml-0"
                    custom-error-msg=""
                    :disabled="model.id"
                    :error="errors[0]"
                    label="RUA:"
                    maxlength="5"
                  />
                  <error
                    v-if="failedRules.required"
                    message="El RUA es requerido."
                    variant="no-border"
                  />
                  <error
                    v-if="failedRules.min || failedRules.max"
                    message="El RUA debe tener una longitud de 4 o 5 caracteres"
                    variant="no-border"
                  />
                </div>
              </validation-provider>
            </div>
            <div class="col-4 col-sm col-12 mb-2 mb-md-0">
              <validation-provider v-slot="{ errors }" rules="required">
                <custom-input
                  v-model="model.emailLawyer"
                  custom-error-msg="El correo electrónico es requerido."
                  :error="errors[0]"
                  label="Correo Electrónico:"
                />
              </validation-provider>
            </div>
            <div class="col-2 col-sm col-12">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: !model.id,
                }"
              >
                <custom-input
                  v-model="model.jobPhone"
                  custom-error-msg="El telefono de la oficina es requerido."
                  :error="errors[0]"
                  label="Teléfono de Oficina:"
                  mask-type="Phone"
                />
              </validation-provider>
            </div>
            <div class="col-2 col-sm col-12 mb-2 mb-md-0">
              <custom-input
                v-model="model.phoneExtension"
                label="Extensión:"
                mask-type="PhoneExt"
              />
            </div>
          </div>
          <div class="row mt-4 mt-sm-0 mt-md-4">
            <div class="col-4 col-sm col-12 mb-2 mb-md-0">
              <custom-input
                v-model="model.cellular"
                label="Número Celular:"
                mask-type="Phone"
              />
            </div>
            <div class="col-4 col-sm col-12 mb-2 mb-md-0">
              <custom-input
                v-model="model.fax"
                label="Número de Fax:"
                mask-type="Phone"
              />
            </div>
          </div>
          <div class="row mt-4 mt-sm-0 mt-md-4">
            <div class="col-2 col-sm col-12 mb-2 mb-md-0">
              <validation-provider v-slot="{ errors }" rules="required">
                <custom-input
                  v-model="model.name"
                  custom-error-msg="El nombre es requerido."
                  :disabled="model.id"
                  :error="errors[0]"
                  label="Nombre:"
                />
              </validation-provider>
            </div>
            <div class="col-2 col-sm col-12 mb-2 mb-md-0">
              <custom-input
                v-model="model.initial"
                :disabled="model.id"
                label="Segundo Nombre:"
              />
            </div>
            <div class="col-4 col-sm col-12 mb-2 mb-md-0">
              <validation-provider v-slot="{ errors }" rules="required">
                <custom-input
                  v-model="model.paternalLastName"
                  custom-error-msg="El apellido paterno es requerido."
                  :disabled="model.id"
                  :error="errors[0]"
                  label="Apellido Paterno:"
                />
              </validation-provider>
            </div>
            <div class="col-4 col-sm col-12">
              <custom-input
                v-model="model.maternalLastName"
                :disabled="model.id"
                label="Apellido Materno:"
              />
            </div>
          </div>
        </div>
      </validation-observer>
      <div v-if="displayLawyerFields && isAddLawyerMode" class="row">
        <div class="col-12 mt-3 d-flex align-items-center justify-content-end">
          <action-button
            i="fas fa-times-circle"
            text="Cancelar"
            variant="outline-danger m-1 mr-3 py-1 btn-block-xs-only"
            @click="onCancelHandler"
          />
          <action-button
            i="fas fa-file-plus"
            text="Guardar"
            variant="success pl-3 pr-3 py-1 btn-block-xs-only"
            @click="onSaveOrAddHandler"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from 'SectionTitle';
import Error from 'custom-error';
import CustomDropDown from 'custom-drop-down';
import CustomInput from 'CustomInput';
import ActionButton from 'ActionButton';
import yesNoOptions from '@/utils/constants/hrrequest/yesNoOptions';
import { addLawyer as _addLawyer } from '@/services/api/complaints.api';
import alert from 'utils/mixins/alert.mixin';
import { phone } from '@/utils/filters';

export default {
  name: 'LegalRepresentation',
  mixins: [alert],
  filters: { phone },
  components: {
    SectionTitle,
    CustomDropDown,
    CustomInput,
    Error,
    ActionButton,
  },
  props: {
    lawyers: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    hasLawyer: null,
    model: {
      id: null,
      rua: null,
      emailLawyer: null,
      jobPhone: null,
      phoneExtension: null,
      cellular: null,
      fax: null,
      name: null,
      initial: null,
      paternalLastName: null,
      maternalLastName: null,
    },
    isAddLawyerMode: false,
    addedLawyers: [],
  }),
  computed: {
    yesNoOptions() {
      return yesNoOptions;
    },
    displayLawyerFields() {
      return this.hasLawyer?.id;
    },
    availableLawyers() {
      const addedIds = this.addedLawyers.map((x) => x.id);
      return this.lawyers.filter((x) => !addedIds.includes(x.id));
    },
  },
  methods: {
    hasLawyerSelectionHandler(value) {
      if (!value?.id) {
        this.model = {};
      }
    },
    getModel() {
      return {
        hasLawyer: this.hasLawyer?.id,
        lawyers: this.addedLawyers.map((x) => x.id),
      };
    },
    onRefreshLawyers() {
      this.$emit('on-refresh-lawyers');
    },
    onChangeLawyerHandler(value) {
      if (value?.id) {
        this.addedLawyers.push(value);
        this.isAddLawyerMode = false;
        this.model.lawyer = null;
      } else {
        this.addedLawyers = [];
      }
    },
    onDeleteHandler(idx) {
      this.addedLawyers.splice(idx, 1);
    },
    onEditHandler(item) {
      this.isAddLawyerMode = true;
      this.model = {
        ...item,
        emailLawyer: item.email,
      };
    },
    onCancelHandler() {
      this.isAddLawyerMode = false;
      this.model = {};
    },
    async onSaveOrAddHandler() {
      const isValid = await this.$refs.legalRepresentationForm.validate();
      if (!isValid) {
        this.ShowToast('Información Incompleta', 'Los cambios.', 'error');
        return;
      }

      const result = (await _addLawyer(this.model)).data;

      if (result.message === 'Success') {
        this.ShowToast(
          'Proceso Exitoso',
          'Los cambios han sido guardados exitosamente.',
          'success'
        );

        if (!this.addedLawyers.find((x) => x.id === this.model.id)) {
          this.addedLawyers.push(result.data);
        } else {
          this.addedLawyers.map((x) => {
            if (x.id === this.model.id) {
              x.email = this.model.emailLawyer;
              x.cellular = this.model.cellular;
              x.jobPhone = this.model.jobPhone;
              x.phoneExtension = this.model.phoneExtension;
              x.fax = this.model.fax;
            }
            return x;
          });
        }
        this.onCancelHandler();
        this.onRefreshLawyers();
      } else {
        this.ShowToast('Alerta', result.message, 'error');
      }
    },
    onNewHandler() {
      this.isAddLawyerMode = true;
      this.model = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.legal-representation {
  ::v-deep .lawyer-form {
    .input-display {
      margin-left: 0 !important;
    }
  }
}
</style>
