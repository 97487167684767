import { http } from '@/core/api/';

const URL = 'api/location';

export const getLocationTypes = async () => {
  return await http.get(`${URL}/locationtypes`);
};

export const getRegions = async () => {
  return await http.get(`${URL}/regions`);
};

export const getMunicipalities = (params) =>
  http.get(`${URL}/municipalities`, { params });

export const getDistricts = (params) =>
  http.get(`${URL}/districts`, { params });

export const getMunicipalitySchools = (params) =>
  http.get(`${URL}/schools`, { params });
